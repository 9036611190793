/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 100099999;
  overflow: visible;
  display: flex;
  top: 10%;
  left: 10%;
  /* transform: translateX(-50%, -50%); */
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
 
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Absolute Center Spinner */
.onboardingLoading {
  position: absolute;
  z-index: 100099999;
  height: 100px;
  width: 230px;
  overflow: visible;
  margin: auto;
  top: 0%;
  left: 8%;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.onboardingLoading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
